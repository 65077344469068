<template>
  <section class="bg-footer">
    <div class="content footer">
      <ul class="footer-itens" v-if="displayItens">
        <li class="footer-item">
          <h1>Planos</h1>
          <ul>
            <li><router-link :to="{ name: 'home', hash: '#planBasic' }">Básico</router-link></li>
            <li><router-link :to="{ name: 'home', hash: '#planStandard' }">Standard</router-link></li>
            <li><router-link :to="{ name: 'home', hash: '#planPremium' }">Premium</router-link></li>
          </ul>
        </li>
        <li class="footer-item">
          <h1>Empresa</h1>
          <ul>
            <li><router-link :to="{ name: 'contact' }">Entre em contato</router-link></li>
            <li><router-link :to="{ name: 'home'}">Ajuda</router-link></li>
          </ul>
        </li>
        <li class="footer-item">
          <h1>JU Financeiro</h1>
          <ul>
            <li><router-link to="#">Features</router-link></li>
            <li><router-link to="#">Aplicativos</router-link></li>
            <li><router-link :to="{ name: 'terms', hash: '#terms' }">Termos de serviço</router-link></li>
            <li><router-link :to="{ name: 'privacyPolice', hash: '#privacyPolice' }">Política de privacidade</router-link></li>
          </ul>
        </li>
      </ul>
      <div class="footer-about-us">
        <h1>Sobre nós</h1>
        <p>O Janela Única Financeiro é um sistema voltado à pessoas físicas e prestadores de serviço que tem a necessidade de  gerenciamento de contas pessoal e empresarial.</p>
      </div>
      <div class="footer-copy">
        <p>@2022. Maia Produção de Softwares Ltda - Todos os direitos reservados</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      displayItens: true
    }
  },
  created() {
        if (window.location.href.includes('link-hub')) {
            this.displayItens = false
        }
    },
}
</script>

<style scoped>
  .bg-footer {
    background: linear-gradient(280.79deg, rgba(44, 190, 249, 0.91) 0%, #386DDA 47.64%, rgba(118, 71, 207, 0.88) 111.39%);
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--c2);
    font-size: 0.875rem;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .footer h1 {
    color: var(--c2);
    font-size: 1.2rem;
  }
  .footer-itens,
  .footer-about-us {
    border-bottom: 1px solid var(--c4);
    width: 100%;
  }
  .footer-item {
    padding: 0 12px 24px 12px;
    position: relative;
  }
  .footer-item:nth-last-child(n+2)::after {
    content: "";
    background: var(--c4);
    position: absolute;
    width: 30px;
    height: 1px;
    bottom: 10px;
    left: 47%;
    opacity: .2;
  }
  .footer-item a {
    color: var(--white);
    letter-spacing: 0;
  }
  .footer-item a:hover {
    border-bottom: 1px solid var(--white);
  }
  .footer-about-us p {
    line-height: 1.5625rem;
    padding: 0 24px;
  }
  .footer-about-us {
    padding: 24px 0;
  }
  .footer-copy {
    padding: 24px 12px;
  }
  @media(min-width: 768px) {
    .footer {
      display: grid;
      grid-template-columns: 2fr 1fr;
      padding: 24px;
      gap: 4px;
    }
    .footer-itens {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 20px;
    }
    .footer-item li {
      padding-left: 0;
    }
    .footer-item h1 {
      text-align: left;
    }
    .footer-itens,
    .footer-about-us {
      border-bottom: none;
    }
    .footer-item {
      text-align: left;
      padding: 0;
    }
    .footer-item:nth-last-child(n+2)::after {
      display: none;
    }
    .footer-about-us>h1,
    .footer-about-us>p {
      text-align: left;
    }
    .footer-about-us p {
      padding: 0;
    }
    .footer-copy {
      grid-column: 1 / -1;
    }
  }
</style>
