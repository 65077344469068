import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "contact" */ '../views/HomeView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/link-hub',
    name: 'link-hub',
    component: () => import(/* webpackChunkName: "links" */ '../views/LinksView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue')
  },
  {
    path: '/privacyPolice',
    name: 'privacyPolice',
    component: () => import(/* webpackChunkName: "privacyPolice" */ '../views/PrivacyPoliceView')
  },
  // External link (Login)
  {
    path: '/login',
    name: 'login',
    beforeEnter: () => {
      window.open('https://financeiro.janelaunica.com.br/#/login', '_blank')
    }
  },
  // External link (Register)
  {
    path: '/register',
    name: 'register',
    beforeEnter: () => {
      window.open('https://financeiro.janelaunica.com.br/#/registro', '_blank')
    }
  },
  /* ========================== rotas do help ========================== */
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "helpView" */ '../views/helpView')
    
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import(/* webpackChunkName: "PainelComponent" */ '../components/help/PainelComponent')
    
  },
  {
    path: '/organizacoesfinenceiras',
    name: 'organizacoesfinenceiras',
    component: () => import(/* webpackChunkName: "OrganizacoesFinenceirasComponent" */ '../components/help/OrganizacoesFinenceirasComponent')
    
  },
  {
    path: '/movimentacoesfinanceiras',
    name: 'movimentacoesfinanceiras',
    component: () => import(/* webpackChunkName: "MovimentacoesFinanceirasComponent" */ '../components/help/MovimentacoesFinanceirasComponent')
    
  },
  {
    path: '/parceiroscomerciais',
    name: 'parceiroscomerciais',
    component: () => import(/* webpackChunkName: "ParceirosComerciaisComponent" */ '../components/help/ParceirosComerciaisComponent')
    
  },
  {
    path: '/nfse',
    name: 'nfse',
    component: () => import(/* webpackChunkName: "NfseComponent" */ '../components/help/NfseComponent')
    
  },
  {
    path: '/contratos',
    name: 'contratos',
    component: () => import(/* webpackChunkName: "ContratosComponent" */ '../components/help/ContratosComponent')
    
  },
  {
    path: '/pessoas',
    name: 'pessoas',
    component: () => import(/* webpackChunkName: "PessoasComponent" */ '../components/help/PessoasComponent')
    
  },
  {
    path: '/contatos',
    name: 'contatos',
    component: () => import(/* webpackChunkName: "NfseComponent" */ '../components/help/ContatosComponent')
    
  },
  {
    path: '/sistema',
    name: 'sistema',
    component: () => import(/* webpackChunkName: "SistemaComponent" */ '../components/help/SistemaComponent')
    
  }
  /* ========================== rotas do help ========================== */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 116,
        behavior: 'smooth'
      }
    } else {
      return { x:0, y:0 }
    }
  }
})

export default router
