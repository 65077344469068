<template>
  <CookieMessage />

  <div class="main-header">
    <HeaderComponent />
  </div>

  <main class="main">
    <router-view />
  </main>

  <div class="main-footer">
    <FooterComponent />
  </div>

</template>

<script>
import CookieMessage from '@/components/cookies/CookieMessage.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'HomeView',
  components: {
    CookieMessage,
    HeaderComponent,
    FooterComponent
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=3994e283-e9e6-46c8-9e27-9498a630e9d6'
    script.id = 'ze-snippet'
    document.body.appendChild(script)
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
