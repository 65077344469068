<template>
  <div class="cookies-bg" v-if="isOpen">
    <div class="cookies">
        <p class="message">{{ message }}</p>
        <p class="links">Ver nossos
            <router-link :to="{ name: 'terms', hash: '#terms' }">Termos de serviço</router-link> e
            <router-link :to="{ name: 'privacyPolice', hash: '#privacyPolice' }">Política de privacidade</router-link>
        </p>
        <div class="buttons">
            <button class="btn btn-gradient accept-cookies" @click="accept">Aceitar e continuar</button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'CookieMessage',
    data () {
        return {
            isOpen: true,
            message: 'Usamos cookies para personalizar conteúdo e anúncios, fornecer recursos de mídia social e analisar nosso tráfego.Também compartilhamos informações sobre seu uso do nosso site com nossos parceiros de mídia social, publicidade e análise, que podem combiná-los com outras informações que você forneceu a eles ou que eles coletaram do uso de seus serviços.'
        }
    },
    created() {
        if(this.cookiesAccepted() === 'true') {
            this.isOpen = false
        }
    },
    methods: {
        cookiesAccepted() {
            return localStorage.getItem('GDPR:accepted', true)
        },
        accept() {
            this.isOpen = false
            return localStorage.setItem('GDPR:accepted', true)
        }
    }
}
</script>

<style scoped>
    .cookies-bg {
        position: fixed;
        width: 100%;
        bottom: 1rem;
        z-index: 1000;
    }
    .cookies {
        background: var(--c5);
        padding: 14px;
        max-width: 90%;
        border-radius: 10px;
        margin: 0 auto;
        display: grid;
        gap: 20px ;

        animation: slideUp .5s forwards;
        opacity: 0;
        transform: translateY(1rem);
    }

    @keyframes slideUp {
        to {
            transform: initial;
            opacity: initial;
        }
    }
    .cookies>.message {
        color: var(--c1);
        font-size: 0.675rem !important;
        text-align: left;
    }
    .cookies>.links  {
        font-size: 0.675rem !important;
        font-size: 2px;
    }
    .cookies a {
        color: var(--p5);
        letter-spacing: 0;
        border-bottom: 1px solid transparent;
        font-weight: 600;
    }
    .cookies a:hover {
        border-bottom: 1px solid var(--p5);
    }
    .buttons {
        display: flex;
        gap: 24px;
    }
    @media (min-width: 751px) {
        .cookies {
            padding: 14px;
        }
        .cookies>.message {
            font-size: 0.875rem !important;
        }
        .cookies>.links  {
            font-size: 0.875rem;
        }
    }
</style>