<template>
    <nav class="nav" v-if="displayNav">
        <ul class="navigation" v-if="!mobile">
            <li v-for="(item, index) in items" :key="index" class="link">
                <template v-if="item.external">
                    <a :href="item.href" target="_blank" rel="noopener noreferrer" :class="item.class">
                        {{ item.description }}
                    </a>
                </template>
                <template v-else>
                    <router-link :to="{ name: item.href, hash: item.hash }" :class="item.class">
                        {{ item.description }}
                    </router-link>
                </template>
            </li>
        </ul>

        <transition name="mobile-nav">
            <ul class="mobile-menu dropdown-nav" v-if="toggleSideBar">
                <li v-for="(item, index) in items" :key="index" @click="toggleSideBar = false">
                    <template v-if="item.external">
                        <a :href="item.href" target="_blank" rel="noopener noreferrer">
                            {{ item.description }}
                        </a>
                    </template>
                    <template v-else>
                        <router-link :to="{ name: item.href, hash: item.hash }">
                            {{ item.description }}
                        </router-link>
                    </template>
                </li>
            </ul>
        </transition>

        <div class="icon">
            <i class="far fa-bars" v-show="mobile" @click="toggleSideBar = !toggleSideBar"
                :class="{ 'icon-active': toggleSideBar }"></i>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'ItemsList',
    data() {
        return {
            mobile: true,
            toggleSideBar: false,
            displayNav: true,
            items: [
                {
                    name: 'introduction',
                    href: 'home',
                    description: 'Introdução',
                    hash: '#introduction',
                    class: '',
                },
                {
                    name: 'functionalities',
                    href: 'home',
                    description: 'Funcionalidades',
                    hash: '#functionalities',
                    class: '',
                },
                {
                    name: 'benefits',
                    href: 'home',
                    description: 'Vantagens',
                    hash: '#benefits',
                    class: '',
                },
                {
                    name: 'plans',
                    href: 'home',
                    description: 'Planos',
                    hash: '#plans',
                    class: '',
                },
                {
                    name: 'faq',
                    href: 'home',
                    description: 'Dúvidas',
                    hash: '#faq',
                    class: '',
                },
                {
                    name: 'contact',
                    href: 'contact',
                    description: 'Contato',
                    hash: '#contact',
                    class: '',
                },
                {
                    name: 'help',
                    href: 'https://maiaproducaodesoftwares.zendesk.com/hc/pt-br/categories/26968033735185-Geral',
                    description: 'Ajuda',
                    hash: '',
                    class: '',
                    external: true
                },
                {
                    name: 'login',
                    href: 'login',
                    description: 'Entrar',
                    hash: '',
                    class: 'btn btn-white',
                }
            ]
        }
    },
    created() {
        window.addEventListener('resize', this.checkscreen)
        this.checkscreen()

        if (window.location.href.includes('link-hub')) {
            this.displayNav = false
        }
    },
    methods: {
        checkscreen() {
            if (window.innerWidth <= 1002) {
                this.mobile = true
                this.toggleSideBar = false
                return
            }

            this.mobile = false
            this.toggleSideBar = false
            return
        }
    }
}
</script>

<style scoped>
a:hover:not(.btn) {
    border-bottom: 1px solid var(--white);
}

.nav {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-family: var(--display);
}

.dropdown-nav {
    background: var(--white);
    display: none;
    display: flex;
    flex-direction: column;
    position: fixed;
    max-width: 350px;
    height: 100vh;
    top: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dropdown-nav>li {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.dropdown-nav>li>a {
    padding: 14px;
    color: var(--p2);
}

.icon>i {
    cursor: pointer;
    font-size: 24px;
    transition: .8s ease all;
    color: var(--white);
}

.icon-active {
    transform: rotate(180deg);
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
    transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
    transform: translateX(-250px);
}

.mobile-nav-enter-to {
    transform: translateX(0);
}

.navigation {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.navigation>li {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 1rem;
}

.navigation a {
    border-bottom: 1px solid transparent;
}

.mobile-menu li {
    width: 250px;
}

.btn-white:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    transition: .5s ease all;
}

@media (min-width: 1200px) {
    .navigation>li {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        font-size: 1rem;
        font-weight: bold;
    }
}
</style>